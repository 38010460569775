
import React, { Component } from 'react'
import { Link } from 'react-router-dom';

const title = "Our Pricing Plan";
const desc = "Choose Your Plan That Fits You Best";


const pricingList = [
    {
        title: '1-Day-Pass',
        desc: 'For Normal Seating',
        btnText: 'Get Ticket',
        price: '$24',
        priceInfo: 'ex. VAT',
        fesList: [
            {
                iconName: 'icofont-check-circled',
                text: 'Event Attendance',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Networking Area Access',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Tea And Coffee',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Lunch',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Free Wifi',
            },
            {
                iconName: 'icofont-check-circled',
                text: '3 Lottery Ticket',
            },
        ],
    },
    {
        title: 'Full Pass',
        desc: 'For Normal Seating',
        btnText: 'Get Ticket',
        price: '$24',
        priceInfo: 'ex. VAT',
        fesList: [
            {
                iconName: 'icofont-check-circled',
                text: 'Event Attendance',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Networking Area Access',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Tea And Coffee',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Lunch',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Free Wifi',
            },
            {
                iconName: 'icofont-check-circled',
                text: '3 Lottery Ticket',
            },
        ],
    },
    {
        title: 'VIP Pass',
        desc: 'For Normal Seating',
        btnText: 'Get Ticket',
        price: '$24',
        priceInfo: 'ex. VAT',
        fesList: [
            {
                iconName: 'icofont-check-circled',
                text: 'Event Attendance',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Networking Area Access',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Tea And Coffee',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Lunch',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Free Wifi',
            },
            {
                iconName: 'icofont-check-circled',
                text: '3 Lottery Ticket',
            },
        ],
    }
]



export default class Pricing extends Component {
    render() {
        return (
            <section className="pricing-plan-section padding-tb padding-b">
                <div className="container">
                    <div className="section-header">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center">
                            {pricingList.map((val, i) => (
                                <div className="col-xl-4 col-md-6 col-12" key={i}>
                                    <div className="pricing-item">
                                        <div className="pricing-inner">
                                            <div className="pricing-header">
                                                <h3>{val.title}</h3>
                                                <p>{val.desc}</p>
                                            </div>
                                            <div className="pricing-content">
                                                <ul className="facilites">
                                                    {val.fesList.map((val, i) => (
                                                        <li className="facility-item" key={i}>
                                                            <span><i className={val.iconName} aria-hidden="true"></i></span> {val.text}
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className="get-ticket">
                                                    <Link to="/login">
                                                        <ul>
                                                            <li className="vat">
                                                                <h4>{val.price}</h4>
                                                                <p>{val.priceInfo}</p>
                                                            </li>
                                                            <li className="icon"><i className="icofont-arrow-right"></i></li>
                                                            <li className="ticket-text">
                                                                <p>{val.btnText}</p>
                                                            </li>
                                                        </ul>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
};
