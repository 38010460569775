import React, { Component } from 'react';

const title = "Search Your keywords";

export default class SearchTwo extends Component {
    render() {
        return (
            <div className="widget widget-search">
                <div className="widget-header">
                    <h5>{title}</h5>
                </div>
                <form className="search-wrapper">
                    <input className="rounded" type="text" name="s" placeholder="Search Here..." />
                    <button type="submit"><i className="icofont-search-2"></i></button>
                </form>
            </div>
        )
    }
};
