import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';

const title = "Login";
const socialTitle = "Login With Social Media";

const socialList = [
    {
        link: '#',
        iconName: 'icofont-facebook',
        className: 'facebook',
    },
    {
        link: '#',
        iconName: 'icofont-twitter',
        className: 'twitter',
    },
    {
        link: '#',
        iconName: 'icofont-linkedin',
        className: 'linkedin',
    },
    {
        link: '#',
        iconName: 'icofont-instagram',
        className: 'instagram',
    },
    {
        link: '#',
        iconName: 'icofont-pinterest',
        className: 'pinterest',
    },
]


export default class LogIn extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Login for Event'} curPage={'Login'} />
                <div className="login-section padding-tb">
                    <div className=" container">
                        <div className="account-wrapper">
                            <h3 className="title">{title}</h3>
                            <form className="account-form">
                                <div className="form-group">
                                    <input type="text" placeholder="User Name" name="username" />
                                </div>
                                <div className="form-group">
                                    <input type="password" placeholder="Password" name="password" />
                                </div>
                                <div className="form-group">
                                    <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                                        <div className="checkgroup">
                                            <input type="checkbox" name="remember" id="remember" />
                                            <label htmlFor="remember">Remember Me</label>
                                        </div>
                                        <Link to="/forgetpass">Forget Password?</Link>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button className="d-block lab-btn"><span>Submit Now</span></button>
                                </div>
                            </form>
                            <div className="account-bottom">
                                <span className="d-block cate pt-10">Don’t Have any Account? <Link to="/registration"> Sign Up</Link></span>
                                <span className="or"><span>or</span></span>
                                <h5 className="subtitle">{socialTitle}</h5>
                                <ul className="social-media social-color lab-ul d-flex justify-content-center">
                                    {socialList.map((val, i) => (
                                        <li key={i}>
                                            <a href={val.link} className={val.className}><i className={val.iconName}></i></a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}
