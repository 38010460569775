import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';
import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import BlogPage from "./page/blog";
import BlogSingle from "./page/blog-single";
import CartPage from "./page/cart";
import ComingSoon from "./page/coming-soon";
import Contact from "./page/contact";
import ForgetPass from "./page/forgetpass";
import GalleryPage from "./page/gallery";
import Home from "./page/home";
import LogIn from "./page/login";
import PricingPage from "./page/pricing";
import Registration from "./page/registration";
import ShopPage from "./page/shop";
import ShopSingle from "./page/shop-single";
import SpeakerDetails from "./page/speaker-details";
import SpeakersPage from "./page/speakers";

function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="gallery" element={<GalleryPage />} />
				<Route path="login" element={<LogIn />} />
				<Route path="forgetpass" element={<ForgetPass />} />
				<Route path="registration" element={<Registration />} />
				<Route path="pricing-plan" element={<PricingPage />} />
				<Route path="shop" element={<ShopPage />} />
				<Route path="shop-single" element={<ShopSingle />} />
				<Route path="cart" element={<CartPage />} />
				<Route path="*" element={<ErrorPage />} />
				<Route path="coming-soon" element={<ComingSoon />} />
				<Route path="speakers" element={<SpeakersPage />} />
				<Route path="speaker-details" element={<SpeakerDetails />} />
				<Route path="blog" element={<BlogPage />} />
				<Route path="blog-single" element={<BlogSingle />} />
				<Route path="contact" element={<Contact />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
