import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import Category from '../component/sidebar/category';
import Pagination from '../component/sidebar/pagination';
import Rating from '../component/sidebar/rating';
import RecentProduct from '../component/sidebar/recent-product';
import Search from '../component/sidebar/search';



const showResult = "Showing 01 - 12 of 139 Results";


const productList = [
    {
        imgUrl: 'assets/images/shop/01.jpg',
        imgAlt: 'shop',
        title: 'Product Text Here',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
        price: '$200.00',
    },
    {
        imgUrl: 'assets/images/shop/02.jpg',
        imgAlt: 'shop',
        title: 'Product Text Here',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
        price: '$200.00',
    },
    {
        imgUrl: 'assets/images/shop/03.jpg',
        imgAlt: 'shop',
        title: 'Product Text Here',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
        price: '$200.00',
    },
    {
        imgUrl: 'assets/images/shop/04.jpg',
        imgAlt: 'shop',
        title: 'Product Text Here',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
        price: '$200.00',
    },
    {
        imgUrl: 'assets/images/shop/05.jpg',
        imgAlt: 'shop',
        title: 'Product Text Here',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
        price: '$200.00',
    },
    {
        imgUrl: 'assets/images/shop/06.jpg',
        imgAlt: 'shop',
        title: 'Product Text Here',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
        price: '$200.00',
    },
    {
        imgUrl: 'assets/images/shop/07.jpg',
        imgAlt: 'shop',
        title: 'Product Text Here',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
        price: '$200.00',
    },
    {
        imgUrl: 'assets/images/shop/08.jpg',
        imgAlt: 'shop',
        title: 'Product Text Here',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
        price: '$200.00',
    },
    {
        imgUrl: 'assets/images/shop/09.jpg',
        imgAlt: 'shop',
        title: 'Product Text Here',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
        price: '$200.00',
    },
]

const ShopPage = () => {
    const [GridList, setGridList] = useState(true);

    return (
        <Fragment>
            <Header />
            <PageHeader title={'Event Shop Page'} curPage={'Shop'} />
            <div className="shop-page padding-tb">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row justify-content-center">
                            <div className="col-lg-9 col-12">
                                <article>
                                    <div className="shop-title d-flex flex-wrap justify-content-between">
                                        <p>{showResult}</p>
                                        <div className={`product-view-mode ${GridList ? "gridActive" : "listActive"}`} >
                                            <a className="grid" onClick={() => setGridList(!GridList)}><i className="icofont-ghost"></i></a>
                                            <a className="list" onClick={() => setGridList(!GridList)}><i className="icofont-listine-dots"></i></a>
                                        </div>
                                    </div>

                                    <div className={`shop-product-wrap row justify-content-center ${GridList ? "grids" : "lists"}`} >
                                        {productList.map((val, i) => (
                                            <div className="col-lg-4 col-sm-6 col-12" key={i}>
                                                <div className="product-item">
                                                    <div className="product-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                        <div className="product-action-link">
                                                            <a href={`${val.imgUrl}`} target="_blank"><i className="icofont-eye"></i></a>
                                                            <a href="#"><i className="icofont-heart-alt"></i></a>
                                                            <a href="#"><i className="icofont-cart-alt"></i></a>
                                                        </div>
                                                    </div>
                                                    <div className="product-content">
                                                        <h6><Link to="/shop-single">{val.title}</Link></h6>
                                                        <Rating />
                                                        <h6>{val.price}</h6>
                                                    </div>
                                                </div>
                                                <div className="product-list-item">
                                                    <div className="product-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                        <div className="product-action-link">
                                                            <a href={`${val.imgUrl}`} target="_blank"><i className="icofont-eye"></i></a>
                                                            <a href="#"><i className="icofont-heart-alt"></i></a>
                                                            <a href="#"><i className="icofont-cart-alt"></i></a>
                                                        </div>
                                                    </div>
                                                    <div className="product-content">
                                                        <h6><Link to="/shop-single">{val.title}</Link></h6>
                                                        <Rating />
                                                        <h6>{val.price}</h6>
                                                        <p>{val.desc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <Pagination />
                                </article>
                            </div>
                            <div className="col-lg-3 col-md-7 col-12">
                                <aside>
                                    <Search />
                                    <Category />
                                    <RecentProduct />
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default ShopPage;