
import React, { Component } from 'react'
import { Link } from 'react-router-dom';

const title = "Event Gifts";
const subTitle = "Our Complimentary Gifts For You";
const desc = "It is a long established fact that a reader will be distracted by theing readable content of a page when looking at its layout. The point info using Lorem Ipsum is that it has a more-or-less";

const eventList = [
    {
        iconName: 'icofont-check-circled',
        text: 'Exclusive T-shirt',
    },
    {
        iconName: 'icofont-check-circled',
        text: 'Lather Bag',
    },
    {
        iconName: 'icofont-check-circled',
        text: 'Mug',
    },
    {
        iconName: 'icofont-check-circled',
        text: 'Gift Hamper',
    },
]



export default class Event extends Component {
    render() {
        return (
            <section className="event-gift-section padding-tb padding-b bg-image">
                <div className="container">
                    <div className="row flex-lg-row-reverse">
                        <div className="col-lg-6 col-12">
                            <div className="section-header">
                                <h2>{title}</h2>
                                <p>{subTitle}</p>
                            </div>
                            <div className="section-wrapper">
                                <div className="gift-content">
                                    <p>{desc} </p>
                                    <ul className="gift-list">
                                        {eventList.map((val, i) => (
                                            <li className="gift-item" key={i}><span><i className={val.iconName}></i> </span> {val.text}</li>
                                        ))}
                                    </ul>
                                    <div className="register-button">
                                        <Link to="/registration" className="lab-btn"><span>Register Now</span> </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="image-part">
                                <img src="assets/images/gift/01.png" alt="gift-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
};
