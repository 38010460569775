
import React, { Component } from 'react';


export default class Pagination extends Component {
    render() {
        return (
            <div className="paginations">
                <ul className="lab-ul d-flex flex-wrap justify-content-center">
                    <li>
                        <a href="#"><i className="icofont-rounded-double-left"></i></a>
                    </li>
                    <li className="d-none d-sm-block">
                        <a href="#">1</a>
                    </li>
                    <li className="d-none d-sm-block">
                        <a href="#">2</a>
                    </li>
                    <li className="d-none d-sm-block">
                        <a className="dot">...</a>
                    </li>
                    <li className="d-none d-sm-block">
                        <a href="#">5</a>
                    </li>
                    <li className="d-none d-sm-block">
                        <a href="#"><i className="icofont-rounded-double-right"></i></a>
                    </li>
                </ul>
            </div>
        )
    }
};
