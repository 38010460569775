import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';


const productCartList = [
    {
        imgUrl: 'assets/images/product/01.jpg',
        imgAlt: 'product',
        title: 'Product Text Here',
        price: '$250',
        totalPrice: '$750',
    },
    {
        imgUrl: 'assets/images/product/02.jpg',
        imgAlt: 'product',
        title: 'Product Text Here',
        price: '$250',
        totalPrice: '$750',
    },
    {
        imgUrl: 'assets/images/product/03.jpg',
        imgAlt: 'product',
        title: 'Product Text Here',
        price: '$250',
        totalPrice: '$750',
    },
    {
        imgUrl: 'assets/images/product/04.jpg',
        imgAlt: 'product',
        title: 'Product Text Here',
        price: '$250',
        totalPrice: '$750',
    },
]



export default class CartPage extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Event Cart Page'} curPage={'Cart Page'} />
                <div className="shop-cart padding-tb">
                    <div className="container">
                        <div className="section-wrapper">
                            <div className="cart-top">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                            <th>Total</th>
                                            <th>Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productCartList.map((val, i) => (
                                            <tr key={i}>
                                                <td className="product-item">
                                                    <div className="p-thumb rounded">
                                                        <Link to="/shop-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                                    </div>
                                                    <div className="p-content">
                                                        <Link to="/shop-single">{val.title}</Link>
                                                    </div>
                                                </td>
                                                <td>{val.price}</td>
                                                <td>
                                                    <div className="cart-plus-minus">
                                                        <div className="dec qtybutton">-</div>
                                                        <input className="cart-plus-minus-box" type="text" name="qtybutton" defaultValue="3" />
                                                        <div className="inc qtybutton">+</div>
                                                    </div>
                                                </td>
                                                <td>{val.totalPrice}</td>
                                                <td>
                                                    <a href="#"><img src="assets/images/del.png" alt="product" /></a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="cart-bottom">
                                <div className="cart-checkout-box">
                                    <form action="/" className="coupon">
                                        <input type="text" name="coupon" placeholder="Coupon Code..." className="cart-page-input-text" />
                                        <input type="submit" value="Apply Coupon" />
                                    </form>
                                    <form action="/" className="cart-checkout">
                                        <input type="submit" value="Update Cart" />
                                        <input type="submit" value="Proceed to Checkout" />
                                    </form>
                                </div>
                                <div className="shiping-box">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="calculate-shiping w-100">
                                                <h4>Calculate Shipping</h4>
                                                <div className="outline-select">
                                                    <select>
                                                        <option value="all">United Kingdom (UK)</option>
                                                        <option value="bangladesh">Bangladesh</option>
                                                        <option value="pakisthan">Pakisthan</option>
                                                        <option value="indea">India</option>
                                                        <option value="nepal">Nepal</option>
                                                    </select>
                                                    <span className="select-icon"><i className="icofont-caret-down"></i></span>
                                                </div>
                                                <div className="outline-select shipping-select">
                                                    <select>
                                                        <option value="all">State/Country</option>
                                                        <option value="dhaka">Dhaka</option>
                                                        <option value="bankok">Benkok</option>
                                                        <option value="kolkata">Kolkata</option>
                                                        <option value="kapasia">Kapasia</option>
                                                    </select>
                                                    <span className="select-icon"><i className="icofont-caret-down"></i></span>
                                                </div>
                                                <input type="text" name="coupon" placeholder="Postcode/ZIP"
                                                    className="cart-page-input-text" />
                                                <button type="submit" className="lab-btn"><span>Update Total</span></button>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="cart-overview">
                                                <h4>Cart Totals</h4>
                                                <ul>
                                                    <li>
                                                        <span className="pull-left">Cart Subtotal</span>
                                                        <p className="pull-right">$ 0.00</p>
                                                    </li>
                                                    <li>
                                                        <span className="pull-left">Shipping and Handling</span>
                                                        <p className="pull-right">Free Shipping</p>
                                                    </li>
                                                    <li>
                                                        <span className="pull-left">Order Total</span>
                                                        <p className="pull-right">$ 2940.00</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
};
