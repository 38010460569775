import React, { Component } from 'react';

const title = "Post Categories";

let categoryList = [
    {
        text: 'Show all',
        count: '149',
        visited: '#',
    },
    {
        text: 'Business',
        count: '20',
        visited: '#',
    },
    {
        text: 'Creative',
        count: '25',
        visited: '#',
    },
    {
        text: 'Inspiation',
        count: '30',
        visited: '#',
    },
    {
        text: 'News',
        count: '28',
        visited: '#',
    },
    {
        text: 'Photography',
        count: '20',
        visited: '#',
    },
    {
        text: 'Smart',
        count: '26',
        visited: '#',
    },
]

export default class CategoryTwo extends Component {
    render() {
        return (
            <div className="widget widget-category">
                <div className="widget-header">
                    <h5>{title}</h5>
                </div>
                <ul className="lab-ul widget-wrapper list-bg-none">
                    {categoryList.map((val, i) => (
                        <li key={i}>
                            <a href={val.visited} className="d-flex flex-wrap justify-content-between"><span><i className="icofont-rounded-double-right"></i>{val.text}</span><span>{val.count}</span></a>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
};
