
import React, { Component } from 'react';

const title = "Event Schedule";
const desc = "A Representation of the event planning";



export default class Schedule extends Component {
    render() {
        return (
            <section className="schedule-section padding-tb padding-b bg-image">
                <div className="container">
                    <div className="section-header">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="section-wrapper shape-b">
                        <div className="row gx-4 gy-5">
                            <div className="col-lg-6">
                                <div className="schedule-left schedule-pack">
                                    <h5>24 December 2021</h5>
                                    <div className="schedule-list" id="accordionExample">
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="headingOne">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/01.png" alt="speaker" />
                                                            <span className="child-thumb">
                                                                <img src="assets/images/event/member/02.png" alt="speaker" />
                                                            </span>
                                                            <span className="child-thumb-2">
                                                                <img src="assets/images/event/member/03.png" alt="speaker" />
                                                            </span>
                                                        </span>
                                                        <span className="h7">Registration & Breakfast</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapseOne" className="accordion-collapse collapse"
                                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true"
                                                    aria-controls="collapseTwo">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/02.png" alt="speaker" />
                                                            <span className="child-thumb">
                                                                <img src="assets/images/event/member/03.png" alt="speaker" />
                                                            </span>
                                                        </span>
                                                        <span className="h7">Digital Event Information</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapseTwo" className="accordion-collapse collapse"
                                                aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="headingThree">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-expanded="true"
                                                    aria-controls="collapseThree">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/03.png" alt="speaker" />
                                                            <span className="child-thumb">
                                                                <img src="assets/images/event/member/02.png" alt="speaker" />
                                                            </span>
                                                        </span>
                                                        <span className="h7">Reinventing Experiences</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapseThree" className="accordion-collapse collapse show"
                                                aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="headingFour">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                                    aria-expanded="true" aria-controls="collapseFour">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/04.png" alt="speaker" />
                                                        </span>
                                                        <span className="h7">Lunch Brack</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapseFour" className="accordion-collapse collapse"
                                                aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="headingFive">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                                    aria-expanded="true" aria-controls="collapseFive">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/05.png" alt="speaker" />
                                                            <span className="child-thumb">
                                                                <img src="assets/images/event/member/06.png" alt="speaker" />
                                                            </span>
                                                        </span>
                                                        <span className="h7">Registration & Breakfast</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapseFive" className="accordion-collapse collapse"
                                                aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="headingSix">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true"
                                                    aria-controls="collapseSix">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/06.png" alt="speaker" />
                                                            <span className="child-thumb">
                                                                <img src="assets/images/event/member/02.png" alt="speaker" />
                                                            </span>
                                                            <span className="child-thumb-2">
                                                                <img src="assets/images/event/member/03.png" alt="speaker" />
                                                            </span>
                                                        </span>
                                                        <span className="h7">Digital Event Information</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapseSix" className="accordion-collapse collapse"
                                                aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="headingSeven">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseSeven"
                                                    aria-expanded="true" aria-controls="collapseSeven">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/07.png" alt="speaker" />
                                                            <span className="child-thumb">
                                                                <img src="assets/images/event/member/02.png" alt="speaker" />
                                                            </span>
                                                        </span>
                                                        <span className="h7">Reinventing Experiences</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapseSeven" className="accordion-collapse collapse"
                                                aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="headingEight">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseEight"
                                                    aria-expanded="true" aria-controls="collapseEight">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/04.png" alt="speaker" />
                                                        </span>
                                                        <span className="h7">Dinner Break</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapseEight" className="accordion-collapse collapse"
                                                aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="headingNine">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseNine"
                                                    aria-expanded="true" aria-controls="collapseNine">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/08.png" alt="speaker" />
                                                            <span className="child-thumb">
                                                                <img src="assets/images/event/member/02.png" alt="speaker" />
                                                            </span>
                                                            <span className="child-thumb-2">
                                                                <img src="assets/images/event/member/03.png" alt="speaker" />
                                                            </span>
                                                        </span>
                                                        <span className="h7">Live Music Performance</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapseNine" className="accordion-collapse collapse"
                                                aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div className="col-lg-6">
                                <div className="schedule-right schedule-pack">
                                    <h5>26 December 2021</h5>
                                    <div className="schedule-list" id="accordionExample-2">
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="heading-One">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapse-One"
                                                    aria-expanded="true" aria-controls="collapse-One">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/01.png" alt="speaker" />
                                                            <span className="child-thumb">
                                                                <img src="assets/images/event/member/02.png" alt="speaker" />
                                                            </span>
                                                            <span className="child-thumb-2">
                                                                <img src="assets/images/event/member/03.png" alt="speaker" />
                                                            </span>
                                                        </span>
                                                        <span className="h7">Registration & Breakfast</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapse-One" className="accordion-collapse collapse"
                                                aria-labelledby="heading-One" data-bs-parent="#accordionExample-2">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="heading-Two">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapse-Two"
                                                    aria-expanded="true" aria-controls="collapse-Two">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/02.png" alt="speaker" />
                                                            <span className="child-thumb">
                                                                <img src="assets/images/event/member/03.png" alt="speaker" />
                                                            </span>
                                                        </span>
                                                        <span className="h7">Digital Event Information</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapse-Two" className="accordion-collapse collapse"
                                                aria-labelledby="heading-Two" data-bs-parent="#accordionExample-2">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="heading-Three">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapse-Three"
                                                    aria-expanded="true" aria-controls="collapse-Three">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/03.png" alt="speaker" />
                                                            <span className="child-thumb">
                                                                <img src="assets/images/event/member/02.png" alt="speaker" />
                                                            </span>
                                                        </span>
                                                        <span className="h7">Reinventing Experiences</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapse-Three" className="accordion-collapse collapse"
                                                aria-labelledby="heading-Three" data-bs-parent="#accordionExample-2">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="heading-Four">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapse-Four"
                                                    aria-expanded="true" aria-controls="collapse-Four">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/04.png" alt="speaker" />
                                                        </span>
                                                        <span className="h7">Lunch Brack</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapse-Four" className="accordion-collapse collapse"
                                                aria-labelledby="heading-Four" data-bs-parent="#accordionExample-2">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="heading-Five">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapse-Five" aria-expanded="true"
                                                    aria-controls="collapse-Five">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/05.png" alt="speaker" />
                                                            <span className="child-thumb">
                                                                <img src="assets/images/event/member/06.png" alt="speaker" />
                                                            </span>
                                                            <span className="child-thumb-2">
                                                                <img src="assets/images/event/member/02.png" alt="speaker" />
                                                            </span>
                                                        </span>
                                                        <span className="h7">Registration & Breakfast</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapse-Five" className="accordion-collapse collapse show"
                                                aria-labelledby="heading-Five" data-bs-parent="#accordionExample-2">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="heading-Six">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapse-Six"
                                                    aria-expanded="true" aria-controls="collapse-Six">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/06.png" alt="speaker" />
                                                            <span className="child-thumb">
                                                                <img src="assets/images/event/member/02.png" alt="speaker" />
                                                            </span>
                                                            <span className="child-thumb-2">
                                                                <img src="assets/images/event/member/03.png" alt="speaker" />
                                                            </span>
                                                        </span>
                                                        <span className="h7">Digital Event Information</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapse-Six" className="accordion-collapse collapse"
                                                aria-labelledby="heading-Six" data-bs-parent="#accordionExample-2">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="heading-Seven">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapse-Seven"
                                                    aria-expanded="true" aria-controls="collapse-Seven">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/07.png" alt="speaker" />
                                                            <span className="child-thumb">
                                                                <img src="assets/images/event/member/02.png" alt="speaker" />
                                                            </span>
                                                        </span>
                                                        <span className="h7">Reinventing Experiences</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapse-Seven" className="accordion-collapse collapse"
                                                aria-labelledby="heading-Seven" data-bs-parent="#accordionExample-2">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="heading-Eight">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapse-Eight"
                                                    aria-expanded="true" aria-controls="collapse-Eight">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/04.png" alt="speaker" />
                                                        </span>
                                                        <span className="h7">Dinner Break</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapse-Eight" className="accordion-collapse collapse"
                                                aria-labelledby="heading-Eight" data-bs-parent="#accordionExample-2">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="heading-Nine">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapse-Nine"
                                                    aria-expanded="true" aria-controls="collapse-Nine">
                                                    <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                        <span className="accor-thumb">
                                                            <img src="assets/images/event/member/08.png" alt="speaker" />
                                                            <span className="child-thumb">
                                                                <img src="assets/images/event/member/02.png" alt="speaker" />
                                                            </span>
                                                            <span className="child-thumb-2">
                                                                <img src="assets/images/event/member/03.png" alt="speaker" />
                                                            </span>
                                                        </span>
                                                        <span className="h7">Live Music Performance</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="collapse-Nine" className="accordion-collapse collapse"
                                                aria-labelledby="heading-Nine" data-bs-parent="#accordionExample-2">
                                                <div className="accordion-body">
                                                    <p>Phosrescen ntiate prncple centered networks via magnetc Apildous eviscuate standards complant fulys tested metrs without creative desi futureproof web</p>
                                                    <ul className="ev-schedule-meta d-flex flex-wrap">
                                                        <li><span><i className="icofont-user"></i></span>Diane, Cuning</li>
                                                        <li><span><i className="icofont-clock-time"></i></span>8:00am - 9:00am</li>
                                                        <li><span><i className="icofont-location-pin"></i></span>Summer C</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="schedule-btns text-center mt-5">
                            <a href="#" className="lab-btn">Download Schedule</a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
};
