import React, { Component } from 'react';

const title = "Instagram";

let instagramList = [
    {
        imgUrl: 'assets/images/gallery/01.jpg',
        imgAlt: 'gallery',
        siteLink: '#',
    },
    {
        imgUrl: 'assets/images/gallery/02.jpg',
        imgAlt: 'gallery',
        siteLink: '#',
    },
    {
        imgUrl: 'assets/images/gallery/03.jpg',
        imgAlt: 'gallery',
        siteLink: '#',
    },
    {
        imgUrl: 'assets/images/gallery/04.jpg',
        imgAlt: 'gallery',
        siteLink: '#',
    },
    {
        imgUrl: 'assets/images/gallery/05.jpg',
        imgAlt: 'gallery',
        siteLink: '#',
    },
    {
        imgUrl: 'assets/images/gallery/06.jpg',
        imgAlt: 'gallery',
        siteLink: '#',
    },
    {
        imgUrl: 'assets/images/gallery/07.jpg',
        imgAlt: 'gallery',
        siteLink: '#',
    },
    {
        imgUrl: 'assets/images/gallery/08.jpg',
        imgAlt: 'gallery',
        siteLink: '#',
    },
    {
        imgUrl: 'assets/images/gallery/09.jpg',
        imgAlt: 'gallery',
        siteLink: '#',
    },
]

export default class Instagram extends Component {
    render() {
        return (
            <div className="widget widget-instagram">
                <div className="widget-header">
                    <h5>{title}</h5>
                </div>
                <ul className="lab-ul widget-wrapper d-flex flex-wrap justify-content-center">
                    {instagramList.map((val, i) => (
                        <li key={i}>
                            <a className="rounded" href={val.siteLink}><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></a>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
};
