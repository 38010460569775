import React, { Component } from 'react';

const title = "Leave a Comment";

export default class Respond extends Component {
    render() {
        return (
            <div id="respond" className="comment-respond rounded">
                <h6 className="h7">{title}</h6>
                <div className="add-comment">
                    <form id="commentform" className="comment-form">
                        <input name="name" type="text" placeholder="Name*" />
                        <input name="email" type="text" placeholder="Email*" />
                        <input name="url" type="text" placeholder="Website*" className="w-100" />
                        <textarea id="comment-reply" name="comment" rows="7" placeholder="Type Here Your Comment*"></textarea>
                        <button type="submit" className="lab-btn"><span>Send Comment</span></button>
                    </form>
                </div>
            </div>
        )
    }
};
