
import React, { Component, Fragment } from 'react'
import Footer from '../component/layout/footer'
import Header from '../component/layout/header'
import About from '../component/section/about'
import Banner from '../component/section/banner'
import Event from '../component/section/event'
import News from '../component/section/news'
import Pricing from '../component/section/pricing'
import Schedule from '../component/section/schedule'
import Speakers from '../component/section/speakers'
import Sponsor from '../component/section/sponsor'



export default class Home extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <Banner />
                <About />
                <Schedule />
                <Speakers />
                <Sponsor />
                <Pricing />
                <Event />
                <News />
                <Footer />
            </Fragment>
        )
    }
};
