import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const quoteTitle = " Rapdiou Qntegrat Distrbute Supply Chains Through Markey Position Bestng Chans Throug Marke Posito Bestin Practces Ieractve Fashon Sound Qources Fashion Economically And Sound Qources ";
const title = "Continually proactive services";
const desc = "It’s no secret that the digital industry is booming. from exciting startups to global brands, to the new companies are reachin boomingesagencies, responding to the new psblites available. however, the industry is exciting fast becoming overcr.";


let metaList = [
    {
        iconName: 'icofont-ui-calendar',
        text: 'October 9, 2019 10:59 am',
    },
    {
        iconName: 'icofont-user',
        text: 'Robot Smith',
    },
    {
        iconName: 'icofont-speech-comments',
        text: '09 Comments',
    },
]

export default class QuotePost extends Component {
    render() {
        return (
            <div className="post-item-2">
                <div className="post-inner">
                    <div className="post-thumb">
                        <blockquote className="blog-quote text-center">
                            <div className="quotes">{quoteTitle}</div>
                        </blockquote>
                    </div>
                    <div className="post-content">
                        <Link to="/blog-single">
                            <h3>{title}</h3>
                        </Link>
                        <ul className="lab-ul post-date">
                            {metaList.map((val, i) => (
                                <li key={i}>
                                    <span><i className={val.iconName}></i> {val.text}</span>
                                </li>
                            ))}
                        </ul>
                        <p>{desc}</p>
                        <Link to="/blog-single" className="lab-btn">Read More</Link>
                    </div>
                </div>
            </div>
        )
    }
};
