import React, { Component } from 'react';
import { Link } from 'react-router-dom';

let cartDataList = [
    {
        imgUrl: 'assets/images/product/menu_cart_01.jpg',
        imgAlt: 'product',
        title: 'Product Text Here',
        quantity: 'Quantity: 1',
        price: '$56',
    },
    {
        imgUrl: 'assets/images/product/menu_cart_02.jpg',
        imgAlt: 'product',
        title: 'Product Text Here',
        quantity: 'Quantity: 1',
        price: '$56',
    },
    {
        imgUrl: 'assets/images/product/menu_cart_03.jpg',
        imgAlt: 'product',
        title: 'Product Text Here',
        quantity: 'Quantity: 1',
        price: '$56',
    },
]

export default class CartList extends Component {
    render() {
        return (
            <ul className="cart-list">
                {cartDataList.map((val, i) => (
                    <li className="cart-item" key={i}>
                        <div className="cart-inner">
                            <div className="cart-thumb">
                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                            </div>
                            <div className="cart-details">
                                <h6><Link to="/shop-single">{val.title}</Link></h6>
                                <p>{val.quantity} <span>{val.price}</span></p>
                                <div className="close-btn">
                                    <a href="#"><i className="icofont-close"></i></a>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        )
    }
}
