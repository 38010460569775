
import React, { Component } from 'react';

export default class Rating extends Component {
    render() {
        return (
            <div className="rating mb-1">
                <span><i className="icofont-ui-rate-blank"></i></span>
                <span><i className="icofont-ui-rate-blank"></i></span>
                <span><i className="icofont-ui-rate-blank"></i></span>
                <span><i className="icofont-ui-rate-blank"></i></span>
                <span><i className="icofont-ui-rate-blank"></i></span>
            </div>
        )
    }
};
