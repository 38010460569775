import React, { Component, Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import ContactInfo from '../component/sidebar/contact-info';
import GoogleMap from '../component/sidebar/google-map';

const title = "Don't Be A Stranger Just Say Hello.";
const desc = "We do fast phone repair. In most to repair your device in just minutes, li we’ll normally get con nection inutes, we’ll normally ge.";

export default class Contact extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Contact us via mail'} curPage={'Contact us'} />
                <div className="contact-section">
                    <div className="contact-top padding-tb aside-bg padding-b">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <article className="contact-form-wrapper">
                                        <div className="contact-form">
                                            <h4>{title}</h4>
                                            <p className="mb-5">{desc}</p>
                                            <form className="comment-form">
                                                <input type="text" name="name" placeholder="Name*" required />
                                                <input type="email" name="email" placeholder="Email*" required />
                                                <input type="text" name="number" placeholder="Number*" required />
                                                <input type="text" name="subject" placeholder="Subject" />
                                                <textarea name="text" id="role" cols="30" rows="9" placeholder="Message*" required></textarea>
                                                <button type="submit" className="lab-btn"><span>Send Our Message</span></button>
                                            </form>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-lg-4">
                                    <ContactInfo />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-bottom">
                        <div className="contac-bottom">
                            <div className="row justify-content-center g-0">
                                <div className="col-12">
                                    <div className="location-map">
                                        <GoogleMap />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
};
