
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import CountDown from '../sidebar/countdown';

const title = "Digital World Meetup 2021";
const desc = "Join Our International Family Today! Please Call us For More info.";


export default class Banner extends Component {
    render() {
        return (
            <section className="banner-section">
                <div className="container">
                    <div className="banner-wrapper shape-a">
                        <div className="row gy-5 align-items-center">
                            <div className="col-lg-6 col-12">
                                <div className="banner-content">
                                    <CountDown />
                                    <h1>{title}</h1>
                                    <p>{desc}</p>
                                    <Link to="/registration" className="lab-btn"><span>Register Now</span> </Link>
                                    <div className="event-sponsored">
                                        <p>Event Sponsored By:</p>
                                        <img src="assets/images/banner/02.png" alt="sponsor" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="banner-image">
                                    <img src="assets/images/banner/01.png" alt="banner-img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
};
