import React, { Component, Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';

const name = "Robot S. Jhonson";
const degi = "Self Development Speaker";
const desc = "Enthusias expedite client-focused growth strategies whereas a client-centered infrastructur ntrinsicl grow optimal e-tailers or rather than effective information.";
const subTitle = "Personal Statement";
const subDesc = "Expedite client-focused growth strateges whereas client centere infrastruc Intrinsicly grow optimal anetailers rather than effective information ollaboratv optimize andin partnerships and hamres frictionless deliverable roactively.";


let infoList = [
    {
        infoTitle: 'Address',
        infoDetails: ' :  Suite 2 & 7, Melbourne, Austrelia',
    },
    {
        infoTitle: 'Email',
        infoDetails: ' :  Robot.jhon@gmail.com',
    },
    {
        infoTitle: 'Phone',
        infoDetails: ' :  +021 548 736 982 ,01236985',
    },
    {
        infoTitle: 'Website',
        infoDetails: ' :  www.adminkagont.com',
    },
    {
        infoTitle: 'Interview',
        infoDetails: ' :  Read The Interview',
    },
]

let followList = [
    {
        iconName: 'icofont-twitter',
        className: 'twitter',
        siteLink: '#',
    },
    {
        iconName: 'icofont-behance',
        className: 'behance',
        siteLink: '#',
    },
    {
        iconName: 'icofont-instagram',
        className: 'instagram',
        siteLink: '#',
    },
    {
        iconName: 'icofont-vimeo',
        className: 'vimeo',
        siteLink: '#',
    },
    {
        iconName: 'icofont-linkedin',
        className: 'linkedin',
        siteLink: '#',
    },
]

export default class SpeakerDetails extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Robot S. Jhonson'} curPage={'Robot S. Jhonson'} />
                <div className="scholar-single-section padding-tb padding-b">
                    <div className="container">
                        <div className="section-wrapper rounded">
                            <div className="section-inner">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6">
                                        <div className="scholar-left">
                                            <div className="scholar-single-item">
                                                <div className="scholar-single-thumb mb-lg-0">
                                                    <img src="assets/images/team/team-single.png" alt="scholar" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="scholar-right">
                                            <div className="scholar-intro">
                                                <h5>{name}</h5>
                                                <span className="d-inline-block">{degi}</span>
                                                <p>{desc}</p>
                                            </div>
                                            <div className="scholar-info">
                                                <span className="h7 mb-3">{subTitle}</span>
                                                <p className="mb-4">{subDesc}</p>
                                                <div className="scholar-other-info">
                                                    <ul className="lab-ul">
                                                        {infoList.map((val, i) => (
                                                            <li key={i}>
                                                                <span className="info-title">{val.infoTitle} </span><span className="info-details"> {val.infoDetails}</span>
                                                            </li>
                                                        ))}
                                                        
                                                        <li><span className="info-title">Follow Us</span> :
                                                            <div className="info-details">
                                                                <ul className="lab-ul d-flex">
                                                                    {followList.map((val, i) => (
                                                                        <li key={i}> <a href={val.siteLink} className={val.className}><i className={val.iconName}></i></a> </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}
