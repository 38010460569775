import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import CountDownTwo from '../component/sidebar/countdown-2';

const title = "Coming Soon";



export default class ComingSoon extends Component {
    render() {
        return (
            <Fragment>
                <section className="coming-soon-section padding-tb">
                    <div className="container">
                        <div className="coming-wrapper text-center">
                            <div className="inner-logo mb-5">
                                <Link to="/"><img src="assets/images/logo/01.png" alt="Kagont" /></Link>
                            </div>
                            <CountDownTwo />
                            <h1 className="coming-soon-title mb-4">{title}</h1>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
};
