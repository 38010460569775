
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Rating from './rating';

const title = "Latests Products";

const recentProductList = [
    {
        imgUrl: 'assets/images/shop/02.jpg',
        imgAlt: 'Product',
        title: 'Organic Orange',
        price: '$59.9',
    },
    {
        imgUrl: 'assets/images/shop/03.jpg',
        imgAlt: 'Product',
        title: 'Organic Orange',
        price: '$59.9',
    },
    {
        imgUrl: 'assets/images/shop/04.jpg',
        imgAlt: 'Product',
        title: 'Organic Orange',
        price: '$59.9',
    },
    {
        imgUrl: 'assets/images/shop/05.jpg',
        imgAlt: 'Product',
        title: 'Organic Orange',
        price: '$59.9',
    },
]


export default class RecentProduct extends Component {
    render() {
        return (
            <div className="widget widget-post primary-bg px-3">
                <div className="widget-header">
                    <h5>{title}</h5>
                </div>
                <ul className="lab-ul widget-wrapper">
                    {recentProductList.map((val, i) => (
                        <li className="d-flex flex-wrap justify-content-between" key={i}>
                            <div className="post-thumb">
                                <Link to="/shop-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                            </div>
                            <div className="post-content ps-3">
                                <Link to="/blog-single">
                                    <h6>{val.title}</h6>
                                </Link>
                                <Rating />
                                <h6>{val.price}</h6>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}
