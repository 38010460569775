import React, { Component, Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import Archive from '../component/sidebar/archive';
import ArticlePagination from '../component/sidebar/article-pagination';
import Authors from '../component/sidebar/authors';
import CategoryTwo from '../component/sidebar/category-2';
import Comments from '../component/sidebar/comments';
import Instagram from '../component/sidebar/instagram';
import RecentPost from '../component/sidebar/recent-post';
import Respond from '../component/sidebar/respond';
import SearchTwo from '../component/sidebar/search-2';
import Tags from '../component/sidebar/tags';

let socialList = [
    {
        link: '#',
        iconName: 'icofont-facebook',
        className: 'facebook',
    },
    {
        link: '#',
        iconName: 'icofont-twitter',
        className: 'twitter',
    },
    {
        link: '#',
        iconName: 'icofont-linkedin',
        className: 'linkedin',
    },
    {
        link: '#',
        iconName: 'icofont-instagram',
        className: 'instagram',
    },
    {
        link: '#',
        iconName: 'icofont-pinterest',
        className: 'pinterest',
    },
]


export default class BlogSingle extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Blog Single Page'} curPage={'Single Page'} />
                <div className="blog-section blog-page padding-tb aside-bg">
                    <div className="container">
                        <div className="section-wrapper">
                            <div className="row justify-content-center pb-15">
                                <div className="col-lg-8 col-12">
                                    <article>
                                        <div className="post-item-2">
                                            <div className="post-inner">
                                                <div className="post-thumb rounded">
                                                    <img src="assets/images/blog/04.jpg" alt="blog" />
                                                </div>
                                                <div className="post-content">
                                                    <h3>Serenity hassir taken posseson</h3>
                                                    <ul className="lab-ul post-date">
                                                        <li><span><i className="icofont-ui-calendar"></i> October 9, 2019 10:59 am </span></li>
                                                        <li><span><i className="icofont-user"></i><a href="#">Robot Smith</a></span></li>
                                                        <li><span><i className="icofont-speech-comments"></i><a href="#">09 Comments</a></span></li>
                                                    </ul>

                                                    <p>Serenity hassir taken posseson of mying entire soung like these sweet mornngs is whch enjoy with my whole heart create am alonesi and feel the charm of exstenceth spotens whch was the blis of souls like mineing am soo happy my dearsi frend absoribed the em exquste sense enjoy with my whole heart inaming alone and feel the charm of exstensc spotsi whch was the blis of souls like mineing am soing happyr my dear frend soingu ize absoribed the exqust sense tranquil existence that neglect my talentsr Ins should byers ncapable ofing is drawng and singe wonderful.</p>

                                                    <blockquote className="single-quote mb-30">
                                                        <div className="quotes">Steal into The nering Sanc How Mysef Down Amon The Hal Gras Buz Lttle World Amon The Stak And Grow Fama With Couns And Fesingri Steal intoing Thene Sanc Hrow Myse Down Amon The Hall Gras Biss The Almighty Among The Staks <span>...Melissa Hunter</span></div>
                                                    </blockquote>

                                                    <p>Serenity hassir taken posseson of mying entire soung like these sweet mornngs is whch enjoy with my whole heart create am alonesi and feel the charm of exstenceth spotens whch was the blis of souls like mineing am soo happy my dearsi frend absoribed the em exquste sense enjoy with my whole heart inaming alone and feel the charm of exstensc spotsi whch was the blis of souls like mineing am soing happyr my dear frend soingu ize absoribed the exqust sense tranquil existence that neglect my talentsr Ins should byers ncapable ofing is drawng and singe wonderful.</p>

                                                    <div className="post-thumb mb-30 rounded"><img src="assets/images/blog/05.jpg" alt="blog-img" /></div>

                                                    <p>Serenity hassir taken posseson of mying entire soung like these sweet mornngs is whch enjoy with my whole heart create am alonesi and feel the charm of exstenceth spotens whch was the blis of souls like mineing am soo happy my dearsi frend absoribed the em exquste sense enjoy with my whole heart inaming alone and feel the charm of exstensc spotsi whch was the blis of souls like mineing am soing happyr my dear frend soingu ize absoribed the exqust sense tranquil existence that neglect my talentsr Ins should byers ncapable ofing is drawng and singe wonderful.</p>

                                                    <div className="post-thumb mb-30 rounded">
                                                        <img src="assets/images/blog/06.jpg" alt="blog" />
                                                        <a href="https://www.youtube.com/embed/SP3yyrboTno" className="play-btn"
                                                            data-rel="lightcase">
                                                            <i className="icofont-play"></i>
                                                            <span className="pluse_2"></span>
                                                        </a>
                                                    </div>

                                                    <p>Serenity hassir taken posseson of mying entire soung like these sweet mornngs is whch enjoy with my whole heart create am alonesi and feel the charm of exstenceth spotens whch was the blis of souls like mineing am soo happy my dearsi frend absoribed the em exquste sense enjoy with my whole heart inaming alone and feel the charm of exstensc spotsi whch was the blis of souls like mineing am soing happyr my dear frend soingu ize absoribed the exqust sense tranquil existence that neglect my talentsr Ins should byers ncapable ofing is drawng and singe wonderful.</p>

                                                    <div className="tags-area pb-0">
                                                        <ul className="tags lab-ul justify-content-center">
                                                            <li>
                                                                <a href="#">Event</a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="active">NoneProfit</a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Admission</a>
                                                            </li>
                                                            <li>
                                                                <a href="#">Exams</a>
                                                            </li>
                                                        </ul>
                                                        <ul className="share lab-ul justify-content-center">
                                                            {socialList.map((val, i) => (
                                                                <li key={i}>
                                                                    <a href={val.link} className={val.className}><i className={val.iconName}></i></a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <ArticlePagination />
                                        <Authors />
                                        <Comments />
                                        <Respond />
                                    </article>
                                </div>
                                <div className="col-lg-4 col-md-7 col-12">
                                    <aside className="ps-lg-4">
                                        <SearchTwo />
                                        <CategoryTwo />
                                        <RecentPost />
                                        <Instagram />
                                        <Archive />
                                        <Tags />
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
};
