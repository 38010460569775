
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const title = "Our Speakers";
const desc = "Meet Our World’s Leading Speakers";


const speakersList = [
    {
        imgUrl: 'assets/images/speakers/01.png',
        imgAlt: 'speakers',
        name: 'Zisnad Q. Alex',
        degi: 'Speaker',
        desc: 'Completey conceplua high quality nice markes rather than long term impact human capital',
        socialList: [
            {
                iconName: 'icofont-twitter',
                siteLink: '#',
            },
            {
                iconName: 'icofont-instagram',
                siteLink: '#',
            },
            {
                iconName: 'icofont-dribble',
                siteLink: '#',
            },
            {
                iconName: 'icofont-vimeo',
                siteLink: '#',
            },
            {
                iconName: 'icofont-behance',
                siteLink: '#',
            },
        ],
    },
    {
        imgUrl: 'assets/images/speakers/02.png',
        imgAlt: 'speakers',
        name: 'Taylor R. Miller',
        degi: 'Speaker',
        desc: 'Completey conceplua high quality nice markes rather than long term impact human capital',
        socialList: [
            {
                iconName: 'icofont-twitter',
                siteLink: '#',
            },
            {
                iconName: 'icofont-instagram',
                siteLink: '#',
            },
            {
                iconName: 'icofont-dribble',
                siteLink: '#',
            },
            {
                iconName: 'icofont-vimeo',
                siteLink: '#',
            },
            {
                iconName: 'icofont-behance',
                siteLink: '#',
            },
        ],
    },
    {
        imgUrl: 'assets/images/speakers/03.png',
        imgAlt: 'speakers',
        name: 'Robot S. Jhonson',
        degi: 'Speaker',
        desc: 'Completey conceplua high quality nice markes rather than long term impact human capital',
        socialList: [
            {
                iconName: 'icofont-twitter',
                siteLink: '#',
            },
            {
                iconName: 'icofont-instagram',
                siteLink: '#',
            },
            {
                iconName: 'icofont-dribble',
                siteLink: '#',
            },
            {
                iconName: 'icofont-vimeo',
                siteLink: '#',
            },
            {
                iconName: 'icofont-behance',
                siteLink: '#',
            },
        ],
    },
    {
        imgUrl: 'assets/images/speakers/04.png',
        imgAlt: 'speakers',
        name: 'Ekez O. Menth',
        degi: 'Speaker',
        desc: 'Completey conceplua high quality nice markes rather than long term impact human capital',
        socialList: [
            {
                iconName: 'icofont-twitter',
                siteLink: '#',
            },
            {
                iconName: 'icofont-instagram',
                siteLink: '#',
            },
            {
                iconName: 'icofont-dribble',
                siteLink: '#',
            },
            {
                iconName: 'icofont-vimeo',
                siteLink: '#',
            },
            {
                iconName: 'icofont-behance',
                siteLink: '#',
            },
        ],
    },
    {
        imgUrl: 'assets/images/speakers/05.png',
        imgAlt: 'speakers',
        name: 'Broklyn L. Doel',
        degi: 'Speaker',
        desc: 'Completey conceplua high quality nice markes rather than long term impact human capital',
        socialList: [
            {
                iconName: 'icofont-twitter',
                siteLink: '#',
            },
            {
                iconName: 'icofont-instagram',
                siteLink: '#',
            },
            {
                iconName: 'icofont-dribble',
                siteLink: '#',
            },
            {
                iconName: 'icofont-vimeo',
                siteLink: '#',
            },
            {
                iconName: 'icofont-behance',
                siteLink: '#',
            },
        ],
    },
    {
        imgUrl: 'assets/images/speakers/06.png',
        imgAlt: 'speakers',
        name: 'Mr. Rassel H.',
        degi: 'Speaker',
        desc: 'Completey conceplua high quality nice markes rather than long term impact human capital',
        socialList: [
            {
                iconName: 'icofont-twitter',
                siteLink: '#',
            },
            {
                iconName: 'icofont-instagram',
                siteLink: '#',
            },
            {
                iconName: 'icofont-dribble',
                siteLink: '#',
            },
            {
                iconName: 'icofont-vimeo',
                siteLink: '#',
            },
            {
                iconName: 'icofont-behance',
                siteLink: '#',
            },
        ],
    },
]



export default class Speakers extends Component {
    render() {
        return (
            <section className="speakers-section padding-tb padding-b">
                <div className="container">
                    <div className="section-header">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="section-wrapper shape-b">
                        <div className="row g-5">
                            {speakersList.map((val, i) => (
                                <div className="col-lg-6 col-12" key={i}>
                                    <div className="speaker-item">
                                        <div className="speaker-inner">
                                            <div className="speaker-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="speaker-content">
                                                <div className="spkr-content-title">
                                                    <h5><Link to="/speaker-details">{val.name}</Link> </h5>
                                                    <p>{val.degi}</p>
                                                </div>
                                                <div className="spkr-content-details">
                                                    <p>{val.desc}</p>
                                                    <ul className="social-icons">
                                                        {val.socialList.map((val, i) => (
                                                            <li key={i}><a href={val.siteLink}><i className={val.iconName}></i></a></li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            
                        </div>
                        <div className="download-button text-center mt-5">
                            <a className="lab-btn">Download Schedule</a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
};
