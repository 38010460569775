import React, { Component } from 'react';

const title = "Get Information";
const desc = "Our Contact information Details and Follow us on social media";

let ContactInfoList = [
    {
        imgUrl: 'assets/images/contact/01.png',
        imgAlt: 'contact',
        title: 'Office Address',
        desc: '1201 park street, Fifth Avenue',
    },
    {
        imgUrl: 'assets/images/contact/02.png',
        imgAlt: 'contact',
        title: 'Phone Number',
        desc: '+22698 745 632,02 982 745',
    },
    {
        imgUrl: 'assets/images/contact/03.png',
        imgAlt: 'contact',
        title: 'Send Mail',
        desc: 'adminKagont@gmil.com',
    },
    {
        imgUrl: 'assets/images/contact/04.png',
        imgAlt: 'contact',
        title: 'Our Website',
        desc: 'www.Kagont-event.com',
    },
]

export default class ContactInfo extends Component {
    render() {
        return (
            <div className="contact-info-wrapper">
                <div className="contact-info-title">
                    <h5>{title}</h5>
                    <p>{desc}</p>
                </div>
                <div className="contact-info-content">
                    {ContactInfoList.map((val, i) => (
                        <div className="contact-info-item" key={i}>
                            <div className="contact-info-inner">
                                <div className="contact-info-thumb">
                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                </div>
                                <div className="contact-info-details">
                                    <span className="fw-bold">{val.title}</span>
                                    <p>{val.desc}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
};
