import React, { Component } from 'react';

const title = "Our Archive";

let archiveList = [
    {
        month: 'January',
        year: '2021',
        visited: '#',
    },
    {
        month: 'February',
        year: '2021',
        visited: '#',
    },
    {
        month: 'March',
        year: '2021',
        visited: '#',
    },
    {
        month: 'August',
        year: '2021',
        visited: '#',
    },
    {
        month: 'September',
        year: '2021',
        visited: '#',
    },
    {
        month: 'October',
        year: '2021',
        visited: '#',
    },
    {
        month: 'November',
        year: '2021',
        visited: '#',
    },
    {
        month: 'December',
        year: '2021',
        visited: '#',
    },
]

export default class Archive extends Component {
    render() {
        return (
            <div className="widget widget-archive">
                <div className="widget-header">
                    <h5>{title}</h5>
                </div>
                <ul className="lab-ul widget-wrapper list-bg-none">
                    {archiveList.map((val, i) => (
                        <li key={i}>
                            <a href={val.visited} className="d-flex flex-wrap justify-content-between"><span><i className="icofont-ui-calendar"></i>{val.month}</span><span>{val.year}</span></a>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
};
