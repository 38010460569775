import React, { Fragment, useState } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';

let GalleryData = [
    {
        id: 1,
        image: 'assets/images/gallery/mason/01.jpg',
        title: 'Title Goes Here',
        desc: 'Subtitle Here',
        catagory: 'eid-ul-adha',
    },
    {
        id: 2,
        image: 'assets/images/gallery/mason/02.jpg',
        title: 'Title Goes Here',
        desc: 'Subtitle Here',
        catagory: 'shab-e-barat',
    },
    {
        id: 3,
        image: 'assets/images/gallery/mason/03.jpg',
        title: 'Title Goes Here',
        desc: 'Subtitle Here',
        catagory: 'eid-ul-adha',
    },
    {
        id: 4,
        image: 'assets/images/gallery/mason/04.jpg',
        title: 'Title Goes Here',
        desc: 'Subtitle Here',
        catagory: 'eid-ul-fitr',
    },
    {
        id: 5,
        image: 'assets/images/gallery/mason/05.jpg',
        title: 'Title Goes Here',
        desc: 'Subtitle Here',
        catagory: 'ramadan',
    },
    {
        id: 6,
        image: 'assets/images/gallery/mason/06.jpg',
        title: 'Title Goes Here',
        desc: 'Subtitle Here',
        catagory: 'shab-e-barat',
    },
    {
        id: 7,
        image: 'assets/images/gallery/mason/07.jpg',
        title: 'Title Goes Here',
        desc: 'Subtitle Here',
        catagory: 'eid-ul-adha',
    },
    {
        id: 8,
        image: 'assets/images/gallery/mason/08.jpg',
        title: 'Title Goes Here',
        desc: 'Subtitle Here',
        catagory: 'shab-e-barat',
    },
    {
        id: 9,
        image: 'assets/images/gallery/mason/09.jpg',
        title: 'Title Goes Here',
        desc: 'Subtitle Here',
        catagory: 'eid-ul-adha',
    },
]


const GalleryPage = () => {
    const [items, setItems] = useState(GalleryData);
    const filterItem = (categItem) => {
        const updateItems = GalleryData.filter((curElem) => {
            return curElem.catagory === categItem;
        });
        setItems(updateItems);
    }

    return (
        <Fragment>
            <Header />
            <PageHeader title={'Our Gallery Page'} curPage={'Gallery'} />
            <div className="gallery-section padding-tb">
                <div className="container">
                    <ul className="filter-button-group lab-ul d-flex justify-content-center mb-4">
                        <li onClick={() => setItems(GalleryData) } className="filter-btn">Show All <span className="filter-item-num">09</span></li>
                        <li onClick={() => filterItem('eid-ul-adha') } className="filter-btn">Seminars <span className="filter-item-num">04</span></li>
                        <li onClick={() => filterItem('eid-ul-fitr') } className="filter-btn">Conferences <span className="filter-item-num">01</span></li>
                        <li onClick={() => filterItem('ramadan') } className="filter-btn">Workshops <span className="filter-item-num">01</span></li>
                        <li onClick={() => filterItem('shab-e-barat') } className="filter-btn">Reunions <span className="filter-item-num">03</span></li>
                    </ul>
                    <div className="grid pb-15 d-flex flex-wrap">
                        {
                            items.map((elem) => {
                            const { id, image, title, desc } = elem;
                                return (
                                    <div className="grid-item" key={id}>
                                        <div className="grid-inner">
                                            <div className="grid-thumb">
                                                <img src={image} alt="gallery-img" />
                                            </div>
                                            <div className="grid-content p-2">
                                                <a href={image} target="_blank"><i className="icofont-expand"></i></a>
                                                <h5>{title}</h5>
                                                <p>{desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default GalleryPage;