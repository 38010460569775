import React, { Component } from 'react';

const title = "02 Comment";

let commentsList = [
    {
        imgUrl: 'assets/images/team/05.jpg',
        imgAlt: 'team thumb',
        name: 'Linsa Faith',
        date: 'Jun 29, 2022 at 12:41 pm',
        desc: 'The inner sanctuary, I throw myself down among the tall grass bye the trckli stream and, as I lie close to the earth',
    },
    {
        imgUrl: 'assets/images/team/06.jpg',
        imgAlt: 'team thumb',
        name: 'Angel Mili',
        date: 'Jun 29, 2022 at 12:41 pm',
        desc: 'The inner sanctuary, I throw myself down among the tall grass bye the trckli stream and, as I lie close to the earth',
    },
]

export default class Comments extends Component {
    render() {
        return (
            <div id="comments" className="comments rounded">
                <h6 className="comment-title h7">{title}</h6>
                <ul className="lab-ul comment-list">
                    {commentsList.map((val, i) => (
                        <li className="comment" key={i}>
                            <div className="com-item">
                                <div className="com-thumb">
                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                </div>
                                <div className="com-content">
                                    <div className="com-title">
                                        <div className="com-title-meta">
                                            <a href="#" className="h7">{val.name}</a>
                                            <span> {val.date} </span>
                                        </div>
                                        <span className="reply"><a className="comment-reply-link" href="#"><i className="icofont-reply-all"></i>Reply</a></span>
                                    </div>
                                    <p>{val.desc}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
};
