import React, { Component, Fragment, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import CartList from '../sidebar/cart-list';



export default class Header extends Component {
    menuTrigger() {
        document.querySelector('.menu').classList.toggle('active')
        document.querySelector('.header-bar').classList.toggle('active')
    }
    render() {
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 200) {
                document.querySelector('.header-section').classList.add(['header-fixed'], ['fadeInUp'])
            }else{
                document.querySelector('.header-section').classList.remove(['header-fixed'], ['fadeInUp'])
            }
        });

        return (
            <Fragment>
                <header className="header-section">
                    <div className="header-bottom">
                        <div className="container">
                            <div className="header-wrapper">
                                <div className="logo">
                                    <Link to="/">
                                        <img src="assets/images/logo/01.png" alt="logo" />
                                    </Link>
                                </div>
                                <div className="menu-area">
                                    <ul className="menu">
                                        <li>
                                            <NavLink to="/">Home</NavLink>
                                        </li>
    
                                        <li className="menu-item-has-children">
                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Features</a>
                                            <ul className="submenu dropdown-menu">
                                                <li><NavLink to="/gallery">Gallery</NavLink></li>
                                                <li><NavLink to="/login">Log In</NavLink></li>
                                                <li><NavLink to="/registration">Sign Up</NavLink></li>
                                                <li><NavLink to="/forgetpass">Forget Pass</NavLink></li>
                                                <li><NavLink to="/pricing-plan">Pricing Plan</NavLink></li>
                                                <li><NavLink to="/shop">Shop</NavLink></li>
                                                <li><NavLink to="/shop-single">Shop Single</NavLink></li>
                                                <li><NavLink to="/cart">Cart Page</NavLink></li>
                                                <li><NavLink to="/404">404 Page</NavLink></li>
                                                <li><NavLink to="/coming-soon">Coming-soon</NavLink></li>
    
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Speakers</a>
                                            <ul className="submenu dropdown-menu">
                                                <li><NavLink to="/speakers">All Speakers</NavLink></li>
                                                <li><NavLink to="/speaker-details">Speaker Details</NavLink></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Blog</a>
                                            <ul className="submenu dropdown-menu">
                                                <li><NavLink to="/blog">Blog</NavLink></li>
                                                <li><NavLink to="/blog-single">Blog Single</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/contact">Contact</Link></li>
                                    </ul>
    
                                    <div className="cart-ticket">
                                        <div className="cart-icon">
                                            <a href="#">
                                                <i className="icofont-bag"></i>
                                                <span>3</span>
                                            </a>
                                            <div className="cart-content">
                                                <CartList />
                                                <Link to="/cart" className="lab-btn">Checkout</Link>
                                            </div>
                                        </div>
                                        <Link to="/pricing-plan" className="ticket-btn lab-btn ">
                                            <span>Purchase Ticket</span>
                                        </Link>
                                    </div>
    
                                    <div className="header-bar d-lg-none" onClick={this.menuTrigger}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </Fragment>
        )
    }
}
