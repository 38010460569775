
import React, { Component } from 'react';
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

const sponsorSlider = [
    {
        imgUrl: 'assets/images/sponsor/01.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/02.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/03.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/04.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/05.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/06.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/07.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/08.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/09.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/10.png',
        imgAlt: 'sponsor',
    },
]




export default class sponsor extends Component {
    render() {
        return (
            <section className="sponsor-section padding-tb padding-b bg-image">
                <div className="container">
                    <div className="section-header">
                        <h2>Event Sponsors</h2>
                        <p>Check Who Makes The Event Possible</p>
                    </div>
                    <div className="section-wrapper text-center shape-b">
                        <div className="all-sponsor">
                            <div className="platinum-sponsor">
                                <Swiper
                                    spaceBetween={20}
                                    slidesPerView={2}
                                    loop={'true'}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    modules={[Autoplay]}
                                    breakpoints={{
                                        0: {
                                            width: 0,
                                            slidesPerView: 1,
                                        },
                                        768: {
                                            width: 768,
                                            slidesPerView: 2,
                                        },
                                        1200: {
                                            width: 1200,
                                            slidesPerView: 4,
                                        },
                                    }}
                                >
                                    {sponsorSlider.map((val, i) => (
                                        <SwiperSlide key={i}>
                                            <div className="sponsor-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                            <div className="gold-sponsor">
                                <div className="swiper-wrapper">
                                    <Swiper
                                        spaceBetween={20}
                                        slidesPerView={2}
                                        loop={'true'}
                                        autoplay={{
                                            delay: 5000,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Autoplay]}
                                        breakpoints={{
                                            0: {
                                                width: 0,
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                width: 768,
                                                slidesPerView: 2,
                                            },
                                            1200: {
                                                width: 1200,
                                                slidesPerView: 5.5,
                                            },
                                        }}
                                    >
                                        {sponsorSlider.map((val, i) => (
                                            <SwiperSlide key={i}>
                                                <div className="sponsor-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                            <div className="silver-sponsor">
                                <div className="swiper-wrapper">
                                    <Swiper
                                        spaceBetween={20}
                                        slidesPerView={2}
                                        loop={'true'}
                                        autoplay={{
                                            delay: 5000,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Autoplay]}
                                        breakpoints={{
                                            0: {
                                                width: 0,
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                width: 768,
                                                slidesPerView: 2,
                                            },
                                            1200: {
                                                width: 1200,
                                                slidesPerView: 6.5,
                                            },
                                        }}
                                    >
                                        {sponsorSlider.map((val, i) => (
                                            <SwiperSlide key={i}>
                                                <div className="sponsor-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                        <a href="#" className="lab-btn"><span>Become a Sponsor</span></a>
                    </div>
                </div>
            </section>
        )
    }
};
