
import React, { Component } from 'react';

const title = "Search keywords";



export default class Search extends Component {
    render() {
        return (
            <div className="widget widget-search primary-bg px-3">
                <div className="widget-header">
                    <h5>{title}</h5>
                </div>
                <form className="search-wrapper">
                    <input type="text" name="s" placeholder="Your Search..." />
                    <button type="submit"><i className="icofont-search-2"></i></button>
                </form>
            </div>
        )
    }
};
