import React, { Component } from 'react';

const name = "Rassel Hossain";
const desc = "Competently conceptualize alternative synergy and technically and niche markets. Efficiently impact technically sound outsourcing rath tnclicks-and-mortar best practices.";

let socialList = [
    {
        link: '#',
        iconName: 'icofont-facebook',
        className: 'facebook',
    },
    {
        link: '#',
        iconName: 'icofont-twitter',
        className: 'twitter',
    },
    {
        link: '#',
        iconName: 'icofont-linkedin',
        className: 'linkedin',
    },
    {
        link: '#',
        iconName: 'icofont-instagram',
        className: 'instagram',
    },
    {
        link: '#',
        iconName: 'icofont-pinterest',
        className: 'pinterest',
    },
]

export default class Authors extends Component {
    render() {
        return (
            <div className="authors rounded">
                <div className="author-thumb">
                    <img src="assets/images/blog/author2.png" alt="author" />
                </div>
                <div className="author-content">
                    <h6>{name}</h6>
                    <p>{desc}</p>
                    <div className="social-media">
                        {socialList.map((val, i) => (
                            <a href={val.link} className={val.className} key={i}><i className={val.iconName}></i></a>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
};
