import React, { Component } from 'react';

export default class ArticlePagination extends Component {
    render() {
        return (
            <div className="article-pagination">
                <div className="prev-article">
                    <a href="#"><i className="icofont-rounded-double-left"></i>Previous Article</a>
                    <p>Evisculate Parallel Processes via Technica Sound Models Authoritative</p>
                </div>
                <div className="next-article">
                    <a href="#">Next Article <i className="icofont-rounded-double-right"></i></a>
                    <p>Qvisculate Parallel Processes via Technica Sound Models Authoritative</p>
                </div>
            </div>
        )
    }
};
