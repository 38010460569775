import React, { Component } from 'react';
import { Link } from 'react-router-dom';


const videoLink = "https://www.youtube.com/embed/SP3yyrboTno";
const title = "Continually proactive services";
const desc = "It’s no secret that the digital industry is booming. from exciting startups to global brands, to the new companies are reachin boomingesagencies, responding to the new psblites available. however, the industry is exciting fast becoming overcr.";


let metaList = [
    {
        iconName: 'icofont-ui-calendar',
        text: 'October 9, 2019 10:59 am',
    },
    {
        iconName: 'icofont-user',
        text: 'Robot Smith',
    },
    {
        iconName: 'icofont-speech-comments',
        text: '09 Comments',
    },
]

export default class VideoPost extends Component {
    render() {
        return (
            <div className="post-item-2">
                <div className="post-inner">
                    <div className="post-thumb rounded">
                        <Link to="/blog-single">
                            <img src="assets/images/blog/03.jpg" alt="blog" />
                        </Link>
                        <a href={videoLink} className="play-btn" target="_blank">
                            <i className="icofont-play"></i>
                            <span className="pluse_2"></span>
                        </a>
                    </div>
                    <div className="post-content">
                        <Link to="/blog-single">
                            <h3>{title}</h3>
                        </Link>
                        <ul className="lab-ul post-date">
                            {metaList.map((val, i) => (
                                <li key={i}>
                                    <span><i className={val.iconName}></i> {val.text}</span>
                                </li>
                            ))}
                        </ul>
                        <p>{desc}</p>
                        <Link to="/blog-single" className="lab-btn">Read More</Link>
                    </div>
                </div>
            </div>
        )
    }
};
