import React, { Component, Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import Event from '../component/section/event';
import Pricing from '../component/section/pricing';



export default class PricingPage extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Purchase a Ticket'} curPage={'Ticket'} />
                <Pricing />
                <Event />
                <Footer />
            </Fragment>
        )
    }
};
