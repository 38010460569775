import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const title = "Recent Post";

let RecentPostList = [
    {
        imgUrl: 'assets/images/product/01.jpg',
        imgAlt: 'product',
        title: 'Poor People’s Campaign Our Resources',
        date: '04 February 2022',
    },
    {
        imgUrl: 'assets/images/product/02.jpg',
        imgAlt: 'product',
        title: 'Boosting Social For NGO And Charities',
        date: '04 February 2022',
    },
    {
        imgUrl: 'assets/images/product/03.jpg',
        imgAlt: 'product',
        title: 'Poor People’s Campaign Our Resources',
        date: '04 February 2022',
    },
    {
        imgUrl: 'assets/images/product/04.jpg',
        imgAlt: 'product',
        title: 'Boosting Social For NGO And Charities',
        date: '04 February 2022',
    },
]

export default class RecentPost extends Component {
    render() {
        return (
            <div className="widget widget-post">
                <div className="widget-header">
                    <h5>{title}</h5>
                </div>
                <ul className="lab-ul widget-wrapper">
                    {RecentPostList.map((val, i) => (
                        <li className="d-flex flex-wrap justify-content-between" key={i}>
                            <div className="post-thumb rounded">
                                <Link to="/blog-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                            </div>
                            <div className="post-content ps-3">
                                <Link to="/blog-single">
                                    <h6>{val.title}</h6>
                                </Link>
                                <p>{val.date}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
};
