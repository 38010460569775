
import React, { Component } from 'react'
import { Link } from 'react-router-dom'


export default class PageHeader extends Component {
    render() {
        const {title, curPage} = this.props;
        return (
            <section className="page-header bg_img padding-tb">
                <div className="overlay"></div>
                <div className="container">
                    <div className="page-header-content-area">
                        <h4 className="ph-title">{title}</h4>
                        <ul className="lab-ul">
                            <li><Link to="/">Home</Link></li>
                            <li><a className="active">{curPage}</a></li>
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}
