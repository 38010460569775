import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

const title = "Big Digital Meetup";
const subTitle = "About The Digital Meetup Conference 2021";
const desc = "Phosrescently ntiate principle centered networks via magnetic services a Entusiasticaly streamline fulys tested metricels apildiously evisculate standards compliant fullys tested metrics without futureproof web services anfullys tested metrice without creative desi futureproof web services without freproof we that and a services enabled apidiously evisculate are standards compliant web services are afor error free";



export default class About extends Component {
    render() {
        return (
            <Fragment>
                <section className="about-section padding-tb padding-b">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="about-image">
                                    <img src="assets/images/about/01.jpg" alt="about image" />
                                    <a href="https://www.youtube.com/embed/SP3yyrboTno" className="play-btn" target="_blank">
                                        <i className="icofont-ui-play"></i>
                                        <span className="pluse_2"></span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="section-header text-center">
                                    <h2>{title}</h2>
                                    <p>{subTitle}</p>
                                </div>
                                <div className="section-wrapper text-center">
                                    <p>{desc}</p>
                                    <div className="about-btn-grp">
                                        <Link to="/registration" className="lab-btn "><span>Register Now</span> </Link>
                                        <Link to="/pricing-plan" className="lab-btn"><span>Purchase Ticket</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
};
