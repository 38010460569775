import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';


let speakerList = [
    {
        imgUrl: 'assets/images/speakers/speaker-2/01.png',
        imgAlt: 'speakers',
        name: 'Robot S. Jhonson',
        degi: 'speaker',
        shapeList: [
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-1.png',
            },
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-2.png',
            },
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-3.png',
            },
        ],
    },
    {
        imgUrl: 'assets/images/speakers/speaker-2/02.png',
        imgAlt: 'speakers',
        name: 'Taylor R. Miller',
        degi: 'speaker',
        shapeList: [
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-1.png',
            },
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-2.png',
            },
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-3.png',
            },
        ],
    },
    {
        imgUrl: 'assets/images/speakers/speaker-2/03.png',
        imgAlt: 'speakers',
        name: 'Zisnad Q. Alex',
        degi: 'speaker',
        shapeList: [
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-1.png',
            },
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-2.png',
            },
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-3.png',
            },
        ],
    },
    {
        imgUrl: 'assets/images/speakers/speaker-2/04.png',
        imgAlt: 'speakers',
        name: 'Ekez O. Menth',
        degi: 'speaker',
        shapeList: [
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-1.png',
            },
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-2.png',
            },
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-3.png',
            },
        ],
    },
    {
        imgUrl: 'assets/images/speakers/speaker-2/05.png',
        imgAlt: 'speakers',
        name: 'Broklyn L. Doel',
        degi: 'speaker',
        shapeList: [
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-1.png',
            },
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-2.png',
            },
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-3.png',
            },
        ],
    },
    {
        imgUrl: 'assets/images/speakers/speaker-2/06.png',
        imgAlt: 'speakers',
        name: 'Mr. Rassel mrh',
        degi: 'speaker',
        shapeList: [
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-1.png',
            },
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-2.png',
            },
            {
                imgUrl: 'assets/images/speakers/speaker-2/shape-3.png',
            },
        ],
    },
]


export default class SpeakersPage extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Our Talented Speakers'} curPage={'Speakers'} />
                <div className="speaker-section padding-tb padding-b">
                    <div className="container">
                        <div className="section-wrapper">
                            <div className="row g-5">
                                {speakerList.map((val, i) => (
                                    <div className="col-lg-6 col-12" key={i}>
                                        <div className="speaker-item-2">
                                            <div className="speaker-inner">
                                                <div className="speaker-thumb addClass" >
                                                    <Link to="/speaker-details">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </Link>
                                                </div>
                                                <div className="speaker-content">
                                                    <div className="speaker-content-inner">
                                                        <h6><Link to="/speaker-details">{val.name}</Link> </h6>
                                                        <p>{val.degi}</p>
                                                    </div>
                                                    <ul className="speaker-shape">
                                                        {val.shapeList.map((val, i) => (
                                                            <li className="shape-1 shape" key={i}>
                                                                <img src={`${val.imgUrl}`} alt="shape" />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
};
