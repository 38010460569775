import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Post from '../component/blog-post/post';
import QuotePost from '../component/blog-post/quote-post';
import SliderPost from '../component/blog-post/slider-post';
import VideoPost from '../component/blog-post/video-post';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import Archive from '../component/sidebar/archive';
import CategoryTwo from '../component/sidebar/category-2';
import Instagram from '../component/sidebar/instagram';
import Pagination from '../component/sidebar/pagination';
import RecentPost from '../component/sidebar/recent-post';
import SearchTwo from '../component/sidebar/search-2';
import Tags from '../component/sidebar/tags';



export default class BlogPage extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Our Blog Page'} curPage={'Blog Page'} />
                <div className="blog-section blog-page padding-tb aside-bg">
                    <div className="container">
                        <div className="section-wrapper">
                            <div className="row justify-content-center">
                                <div className="col-lg-8 col-12">
                                    <article>
                                        <Post />
                                        <SliderPost />
                                        <VideoPost />
                                        <QuotePost />
                                        <Pagination />
                                    </article>
                                </div>
                                <div className="col-lg-4 col-md-7 col-12">
                                    <aside className="ps-lg-4">
                                        <SearchTwo />
                                        <CategoryTwo />
                                        <RecentPost />
                                        <Instagram />
                                        <Archive />
                                        <Tags />
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
};
