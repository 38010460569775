
import React, { Component } from 'react';

const title = "All Categories";


export default class Category extends Component {
    render() {
        return (
            <div className="widget widget-category primary-bg px-3">
                <div className="widget-header">
                    <h5>{title}</h5>
                </div>
                <div className="widget-wrapper">
                    <ul className="lab-ul shop-menu">
                        <li className="menu-item-has-children">
                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Apple</a>
                            <ul className="lab-ul shop-submenu dropdown-menu">
                                <li><a href="#">Apple 1</a></li>
                                <li><a href="#">Apple 2</a></li>
                                <li><a href="#">Apple 3</a></li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Orange</a>
                            <ul className="lab-ul shop-submenu dropdown-menu">
                                <li><a href="#">Orange 1</a></li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Tomato</a>
                            <ul className="lab-ul shop-submenu dropdown-menu">
                                <li><a href="#">Tomato 1</a></li>
                                <li><a href="#">Tomato 2</a></li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Honey</a>
                            <ul className="lab-ul shop-submenu dropdown-menu">
                                <li><a href="#">Honey 1</a></li>
                                <li><a href="#">Honey 2</a></li>
                                <li><a href="#">Honey 3</a></li>
                                <li><a href="#">Honey 4</a></li>
                                <li><a href="#">Honey 5</a></li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Banana</a>
                            <ul className="lab-ul shop-submenu dropdown-menu">
                                <li><a href="#">Banana 1</a></li>
                                <li><a href="#">Banana 2</a></li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Blueberry</a>
                            <ul className="lab-ul shop-submenu dropdown-menu">
                                <li><a href="#">Blueberry 1</a></li>
                                <li><a href="#">Blueberry 2</a></li>
                                <li><a href="#">Blueberry 3</a></li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Lettuce</a>
                            <ul className="lab-ul shop-submenu dropdown-menu">
                                <li><a href="#">Lettuce 1</a></li>
                                <li><a href="#">Lettuce 2</a></li>
                                <li><a href="#">Lettuce 3</a></li>
                                <li><a href="#">Lettuce 4</a></li>
                                <li><a href="#">Lettuce 5</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
};
